.platform-links {
  &__container {
    @apply mb-4;
    grid-area: platform;

    @screen md {
      @apply ml-auto mr-auto mb-0;
    }
  }

  &__title {
    @apply uppercase font-bold;
  }

  &__link {
    @apply pl-2 transition-colors text-accent-500;

    &:hover {
      @apply text-white;
    }
  }
}
