.tos-link {
  &__link {
    @apply text-xs transition-colors text-accent-500;
    grid-area: tos;

    &:hover {
      @apply text-white;
    }

    @screen md {
      @apply justify-self-end;
    }
  }
}
