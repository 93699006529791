.copyright-notice {
  &__link {
    @apply text-xs text-black-300 transition-colors text-accent-500;
    grid-area: copyright;

    &:hover {
      @apply text-white;
    }

    @screen md {
      @apply justify-self-end;
    }
  }
}
