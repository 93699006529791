html {
  @apply overflow-x-hidden;

  body {
    margin-right: 0px!important;
  }
}

body {
  @apply overflow-hidden text-white bg-black-500 mt-2;
}
