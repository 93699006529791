.contact-link {
  &__link {
    @apply mb-4 font-bold uppercase text-s transition-colors text-accent-500;
    grid-area: contact;

    &:hover {
      @apply text-white;
    }

    @screen md {
      @apply mb-0;
    }
  }
}
