.social-links {
  &__container {
    @apply mb-8 grid grid-flow-col content-center gap-6;
    grid-area: social;

    @screen md {
      @apply mb-0 gap-5;
    }
  }

  &__link {
    @apply grid aspect-square w-6 content-center justify-items-center transition-colors text-accent-500;

    &:hover {
      @apply text-white;
    }

    @screen md {
      @apply w-5;
    }
  }
}
