.application-footer {
  &__bar {
    @apply border-t border-white pt-8 pb-12;

    @screen md {
      @apply pt-6 pb-7;
    }
  }

  &__container {
    @apply container mx-auto grid justify-items-start;
    grid-template-areas: "contact" "social" "platform" "community" "tos" "copyright";

    @screen md {
      grid-template-areas:
        "contact  platform  community tos"
        "social   platform  community copyright";
    }
  }
}
