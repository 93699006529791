/* purgecss start ignore */
@tailwind base;
/* ▼ BASE ▼ */
@import '~/styles/reset';
@import '~/styles/fonts/space-grotesk';

@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
/* ▲ BASE ▲ */

@tailwind components;
/* ▼ COMPONENTS ▼ */
/* ▲ COMPONENTS ▲ */

/* purgecss end ignore */

@tailwind utilities;
/* ▼ UTILITIES ▼ */
/* ▲ UTILITIES ▲ */
