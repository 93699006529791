.navigation-bar {
  &__fix {
    @apply fixed inset-x-0 top-0 z-navigation-bar bg-black-500;
  }

  &__bar {
    @apply  border-b border-white;
  }

  &__container {
    @apply container flex mx-auto h-20 items-center justify-between gap-6;
  }

  &__wallet-button {
    @apply bg-accent-500;
  }

  &__right {
    @apply flex items-center gap-6;

    button {
      @apply cursor-pointer font-bold font-sans uppercase rounded-full border transition-all border-white lg:w-auto;
      min-width: 120px!important;

      &:hover, &:focus {
        @apply rounded-full;
      }

      svg:first-child, svg:nth-child(3) {
        @apply hidden;
      }

      @screen md {
        @apply text-title-xxs px-5 py-2.5;

        svg:first-child, svg:nth-child(3) {
          @apply block;
        }
      }
    }
  }
}
