.buy-crypto-link {
  &__container {
    @apply container flex mx-auto my-2;
  }

  &__link {
    @apply ml-auto uppercase text-xxs text-feedback-positive;

    &:hover {
      @apply text-white;
    }
  }

  &__icon {
    @apply inline-block w-3 align-middle;
  }
}
