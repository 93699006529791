@font-face {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('SpaceGrotesk-Light'),
    url('~/public/fonts/SpaceGrotesk-Light.woff') format('woff'),
    url('~/public/fonts/SpaceGrotesk-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('SpaceGrotesk-Regular'),
    url('~/public/fonts/SpaceGrotesk-Regular.woff') format('woff'),
    url('~/public/fonts/SpaceGrotesk-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('SpaceGrotesk-Medium'),
    url('~/public/fonts/SpaceGrotesk-Medium.woff') format('woff'),
    url('~/public/fonts/SpaceGrotesk-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('SpaceGrotesk-SemiBold'),
    url('~/public/fonts/SpaceGrotesk-SemiBold.woff') format('woff'),
    url('~/public/fonts/SpaceGrotesk-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('SpaceGrotesk-Bold'),
    url('~/public/fonts/SpaceGrotesk-Bold.woff') format('woff'),
    url('~/public/fonts/SpaceGrotesk-Bold.ttf') format('truetype');
}
